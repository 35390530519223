<template>
  <v-edit-dialog
    :return-value.sync="fieldData"
    large
    persistent
    @save="save"
  >
    <p>{{ fieldData || "Not Provided" }}</p>
    <template v-slot:input>
      <div class="mt-4 text-h6">
        Update {{ fieldName }}
      </div>
      <v-text-field
        v-model="fieldData"
        autofocus
        label="Edit"
      />
    </template>
  </v-edit-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'UserItem',
    props: {
      fieldName: String,
      fieldData: String,
    },
    computed: {
      ...mapGetters(['selectedUser']),
    },
    methods: {
      save () {
        const role = this.selectedUser.role
        const id = this.selectedUser.id
        const userUpdateDetails = {
          id: id,
          role: role,
        }
        userUpdateDetails[this.fieldName] = this.fieldData
        // console.log(userUpdateDetails)
        this.$store.dispatch('updateUser', userUpdateDetails)
      },
    },
  }
</script>

<style>

</style>
