<template>
  <div>
    <v-container
      fluid
      class="down-top-padding"
    >
      <BaseBreadcrumb
        :title="selectedUser.display_name"
        :icon="page.icon"
        :breadcrumbs="breadcrumbs"
      />
      <v-row>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar
                flat
              >
                <v-spacer />
                <template v-if="selectedUser.verified === true && selectedUser.active === true && loggedInUser.userType === 'Administrator'">
                  <v-btn
                    outlined
                    class="mr-4"
                    color="red darken-2"
                    @click="archive"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon> Archive
                  </v-btn>
                </template>
              </v-toolbar>

              <v-toolbar flat>
                <v-toolbar-title>Administrator Details</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-row>
                <v-col cols="4">
                  <center>
                    <v-avatar
                      color="indigo"
                      size="100"
                    >
                      <v-icon dark>
                        mdi-account-circle
                      </v-icon>
                    </v-avatar>
                  </center>
                  <h1
                    style="font-size: 1rem; text-align: center"
                    class="mt-2"
                  >
                    {{ selectedUser.firstName }}
                  </h1>
                  <p style="text-align: center">
                    {{ selectedUser.email }}
                  </p>
                </v-col>
                <v-divider
                  vertical
                />
                <v-col cols="8">
                  <template v-if="defaultClientDetailType === 'General'">
                    <v-row>
                      <!-- <v-col
                                                cols="12"
                                                md="4"
                                            >
                                                <h3 class="darken-2" style="font-size: 1rem; color: grey">Given Name</h3>
                                                <br />
                                                <p>{{selectedUser.given_name || "Not Provided"}}</p>
                                                <v-divider />
                                            </v-col> -->
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Given Name
                        </h3>
                        <br>
                        <user-item
                          field-name="given_name"
                          :field-data="selectedUser.contact.givenName"
                        />
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Surname
                        </h3>
                        <br>
                        <user-item
                          field-name="surname"
                          :field-data="selectedUser.contact.surname"
                        />
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          User Type
                        </h3>
                        <br>
                        <p>{{ selectedUser.role || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Mobile
                        </h3>
                        <br>
                        <user-item
                          field-name="phone"
                          :field-data="selectedUser.contact.phone"
                        />
                        <v-divider />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-if="defaultClientDetailType === 'Location'">
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Address Line 1
                        </h3>
                        <br>
                        <p>{{ selectedUser.location.addressLine1 || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Address Line 2
                        </h3>
                        <br>
                        <p>{{ selectedUser.location.addressLine2 || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Post Code
                        </h3>
                        <br>
                        <p>{{ selectedUser.location.postcode || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Suburb
                        </h3>
                        <br>
                        <p>{{ selectedUser.location.suburb || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          State
                        </h3>
                        <br>
                        <p>{{ selectedUser.location.state || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Country
                        </h3>
                        <br>
                        <p>{{ selectedUser.location.country || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Spare Key Location
                        </h3>
                        <br>
                        <p>{{ selectedUser.location.spareKeyLocation || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Key Pad Code
                        </h3>
                        <br>
                        <p>{{ selectedUser.location.keyPadCode || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-if="defaultClientDetailType === 'Next of Kin'">
                    <v-row>
                      No Data
                    </v-row>
                  </template>
                  <template v-if="defaultClientDetailType === 'Representitives'">
                    <v-row>
                      No Data
                    </v-row>
                  </template>
                  <template v-if="defaultClientDetailType === 'Careers'">
                    <v-row>
                      No Data
                    </v-row>
                  </template>
                  <!-- <ul>
                                        <li v-for="(value, name) in selectedUser" :key="name">
                                            <strong>{{name}}</strong> : {{ value }}
                                        </li>
                                    </ul> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import UserItem from '../itemUpdate.vue'

  export default {
    name: 'Registrant',
    components: {
      UserItem,
    },
    data () {
      return {
        dogs: '',
        page: {
          title: 'Your Roster',
          icon: 'user',
        },
        breadcrumbs: [
          {
            text: 'Users',
            disabled: false,
            to: '/user/users/',
          },
          {
            text: 'User Details',
            disabled: true,
          },
        ],
        auditHeaders: [
          {
            text: 'Reason',
            value: 'reason',
          },
          {
            text: 'Resource',
            value: 'route',
          },
          {
            text: 'Method',
            value: 'method',
          },
          {
            text: 'When',
            value: 'createdAt',
          },
        ],
        clientDetailTypes: [
          'General',
          'Location',
          'Next of Kin',
          'Representitives',
          'Career',
        ],
        defaultClientDetailType: 'General',
      }
    },
    created () {
      this.$store.dispatch('viewUser', this.$route.params.id)
    },
    computed: {
      ...mapGetters(['selectedUser']),
    },
    methods: {
      back () {
        this.$router.back()
      },
      changeClientDetailsScreen (screen) {
        this.defaultClientDetailType = screen
      },
      approve () {
        this.$store.dispatch('approveRegistrant', this.registrantQuery._id)
      },
      refreshAudit () {
        this.$store.dispatch('showUserAudit')
      },
    },
  }
</script>
